<template>
  <div id="simple-calendar-app">
    <div class="vx-card no-scroll-content calendar-header">
      <div class="mob-h145 mob-px-2">
        <CalendarHeader>
          <template slot="views">
            <div class="flex">
              <SelectMonth
                :showDate="showDate"
                @monthChanged="setMonthFromSelect"
                :view="calendarView"
              />
              <vs-button
                type="filled"
                class="md:px-4 fsize13 border-radius-button border-l-0 rounded-l-none cust-w93"
                @click="handleView"
                >{{ viewButtonText }}</vs-button
              >
              <!-- <template v-for="(view, index) in calendarViewTypes">
                  <vs-button
                    v-if="calendarView === view.val"
                    :key="String(view.val) + 'filled'"
                    type="filled"
                    class="fsize13 md:px-4  border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarViewTypes.length !== index + 1
                    }"
                    @click="calendarView = view.val"
                    >{{ view.label }}</vs-button
                  >
                  <vs-button
                    v-else
                    :key="String(view.val) + 'border'"
                    type="border"
                    class="md:px-4 fsize13 border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarViewTypes.length !== index + 1
                    }"
                    @click="calendarView = view.val"
                    >{{ view.label }}</vs-button
                  >
                </template> -->
            </div>
          </template>
          <template slot="month">
            <div class="mt-1">
              <!-- <div class="flex justify-around">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-6 h-6 m-1"
                  class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                  :title="'Previous ' + calendarView"
                />

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-6 h-6 m-1 "
                  class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                  :title="'Next ' + calendarView"
                />
              </div> -->

              <span
                class="mx-3 text-xl font-medium whitespace-no-wrap month-text"
                >Treasury Calendar</span
              ><br />
              <div
                class="flex justify-center items-center mr-3 mob-cap-tabs-fx"
              >
                <template v-for="(view, index) in calendarPages">
                  <vs-button
                    v-if="currentRoute === view.val"
                    :key="String(view.val) + 'filled'"
                    type="filled"
                    class="min-px-4 fsize13 lg:w-1/4 mob-h40 mob-c-pd border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarPages.length !== index + 1,
                    }"
                    @click="navigateTo(view.val)"
                    >{{ view.label }}</vs-button
                  >
                  <vs-button
                    v-else
                    :key="String(view.val) + 'border'"
                    type="border"
                    class="min-px-4 lg:w-1/4 mob-h40 mob-c-pd fsize13 border-radius-button"
                    :class="{
                      'border-l-0 rounded-l-none': index,
                      'rounded-r-none': calendarPages.length !== index + 1,
                    }"
                    @click="navigateTo(view.val)"
                    >{{ view.label }}</vs-button
                  >
                </template>
              </div>
            </div>

            <div id="div-with-loading" class="vs-con-loading__container"></div>
          </template>
        </CalendarHeader>
      </div>
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :items="simpleCalendarEvents"
        :eventTop="windowWidth <= 400 ? '2rem' : '0.01rem'"
        eventBorderHeight="0px"
        eventContentHeight="3rem"
        class="theme-default header-height"
        :class="{
          'flex flex-row flex-wrap': isMobileView && calendarView === 'week',
          'calendar-cust-height': !(isMobileView && calendarView === 'week'),
        }"
        :periodChangedCallback="periodChanged"
        :weekdayNameFormat="isMobileView ? 'short' : 'long'"
        :startingDayOfWeek="1"
        :display-week-numbers="!isMobileView"
        @click-date="handleDateClick"
      >
        <div slot="header" class="mob-h145"></div>
        <div
          slot="dayContent"
          slot-scope="{ day }"
          class="flex justify-end items-end w-full lg:pr-3 md:pr-3 pr-2"
        >
          <feather-icon
            icon="PlusIcon"
            class="cursor-pointer w-4 text-gray"
            title="Add Note"
            @click.stop="toggleNotePopup(true, day)"
            v-if="!dayNote(day)"
          ></feather-icon>
          <img
            src="@/Client/Kotak/img/pin.png"
            class="cursor-pointer mb-2 mr-1 w-4"
            v-if="dayNote(day)"
            :title="dayNote(day).note"
            @click.stop="handleNoteClick(day)"
          />

          <img
            src="@/Client/Kotak/img/scrollsvg.svg"
            style="fill: #fff"
            class="cursor-pointer w-4 h-4 mb-2"
            title="Scroll"
            v-if="isOverflow(day) && calendarView !== 'week'"
            @click.stop="scrollToEnd(day)"
          />

          <!-- <feather-icon
            icon="Maximize2Icon"
            class="cursor-pointer text-gray rotate-scroll-icon"
            title="Scroll"
            v-if="isOverflow(day) && calendarView !== 'week'"
            @click.stop="scrollToEnd(day)"
          ></feather-icon> -->
        </div>
        <div slot="item" slot-scope="{ value, top }">
          <vx-tooltip
            :text="removeHTML(value.title)"
            :left="`calc(100%/7 *${modifiedDayOfDate(value.endDate) * 1})`"
            :class="`calc(100%/7 *${modifiedDayOfDate(value.endDate) * 1})`"
          >
            <div
              @click="handleHolidayClick(value)"
              draggable="false"
              :class="`cv-item ${value.classes.join(' ')} ${
                calendarView !== 'week' ? 'height-scroll' : ''
              }
              ${
                isMobileView && calendarView === 'week'
                  ? 'item-width readmore-clamp-3'
                  : ''
              }`"
              :style="`top: ${
                isMobileView && calendarView === 'week'
                  ? 'calc(' +
                    (dayOfDate(
                      new Date(value.endDate).getFullYear(),
                      new Date(value.endDate).getMonth(),
                      new Date(value.endDate).getDate()
                    ) == 0
                      ? 7
                      : dayOfDate(
                          new Date(value.endDate).getFullYear(),
                          new Date(value.endDate).getMonth(),
                          new Date(value.endDate).getDate()
                        ) - 1) +
                    '*(100%/7))'
                  : top
              }`"
              v-html="value.title"
              v-if="!(isMobileView && calendarView !== 'week')"
              :id="new Date(value.endDate).toISOString()"
            ></div>
          </vx-tooltip>

          <!-- <div
            :class="
              `cv-item h-1 w-1 rounded-lg bg-primary p-1 ml-1 ${itemClasses(
                value.classes
              )}`
            "
            :style="`top: ${setTop(top)}`"
            v-if="isMobileView && calendarView !== 'week'"
          ></div> -->
        </div>
        <div
          slot="weekNumber"
          slot-scope="{ date }"
          class="cursor-pointer flex flex-col"
          @click.stop="setWeekView(date)"
        >
          <div class="week-number-text flex">
            Week
            <div class="ml-2">{{ getWeekOfMonth(date, showDate) }}</div>
          </div>
        </div>
      </calendar-view>

      <div class="vx-row no-gutter px-3 w-full flex flex-row">
        <div class="md:w-8/12 w-full">
          <p
            class="w-full flex justify-start mb-1 text-calendar-blue text-xs font-bold px-3"
          >
            Last working days of next 12 months
          </p>
          <div class="w-full overflow-x-auto flex justify-start">
            <table class="text-calendar-blue table-border-calendar-blue">
              <tbody
                v-for="(item, index) in lastWorkingDaysView"
                :key="item.month + '-' + index"
              >
                <tr v-if="index % 6 === 0">
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{ item.month }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">{{ item.day }}</td>
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{
                      lastWorkingDaysView[index + 1] &&
                      lastWorkingDaysView[index + 1].month
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">
                    {{
                      lastWorkingDaysView[index + 1] &&
                      lastWorkingDaysView[index + 1].day
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{
                      lastWorkingDaysView[index + 2] &&
                      lastWorkingDaysView[index + 2].month
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">
                    {{
                      lastWorkingDaysView[index + 2] &&
                      lastWorkingDaysView[index + 2].day
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{
                      lastWorkingDaysView[index + 3] &&
                      lastWorkingDaysView[index + 3].month
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">
                    {{
                      lastWorkingDaysView[index + 3] &&
                      lastWorkingDaysView[index + 3].day
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{
                      lastWorkingDaysView[index + 4] &&
                      lastWorkingDaysView[index + 4].month
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">
                    {{
                      lastWorkingDaysView[index + 4] &&
                      lastWorkingDaysView[index + 4].day
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue text-black">
                    {{
                      lastWorkingDaysView[index + 5] &&
                      lastWorkingDaysView[index + 5].month
                    }}
                  </td>
                  <td class="p-2 table-border-calendar-blue">
                    {{
                      lastWorkingDaysView[index + 5] &&
                      lastWorkingDaysView[index + 5].day
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p class="fsize10 line-15 p-2 pb-2">
            <span class="font-semibold">Data Sources:</span>
            Holidays, date releases and Central Bank announcement – Reserve Bank
            of India, Bloomberg and the respective country’s official central
            bank websites.
          </p>
          <p class="fsize8 line-15 pb-2 px-2">
            <span class="font-bold">Disclaimer:</span>
            In the preparation of the material content on this page, we have
            used information that is publicly available and is static in nature.
            Information gathered & material used in this document is from
            reliable sources.
          </p>
        </div>
        <div class="md:w-4/12 mob-mt-10 w-full h-18 pl-2">
          <div class="flex items-center">
            <span class="cust-dotweek bg-warning"></span>
            <p class="text-xs ml-2 font-normal text-calendar-blue mr-2">
              Current Date, Current Week
            </p>
            <span>
              <feather-icon
                icon="PlusIcon"
                class="cursor-pointer w-5 text-gray"
              ></feather-icon
            ></span>
            <!-- <span>/</span>
            <span>
              <img
                src="@/Client/Kotak/img/pin.png"
                class="cursor-pointer mb-2 w-4"
              />-->
            <span class="text-xs text-calendar-blue ml-1"> Add Note</span>
            <span>
              <feather-icon
                icon="Maximize2Icon"
                class="cursor-pointer w-5 text-gray rotate-scroll-icon ml-2"
              ></feather-icon>
            </span>
            <span class="text-xs text-calendar-blue ml-1">Scroll</span>
          </div>
          <div class="flex flex-wrap">
            <span
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="label-font h-4"
            >
              <!-- <div
              class="h-3 w-3 inline-block rounded-full mr-2"
              :class="'bg-' + label.color"
            ></div> -->

              <span :class="'text-' + label.color"
                >{{ label.text
                }}<span v-if="index !== calendarLabels.length - 1" class="mr-1"
                  >,</span
                ></span
              >
            </span>
          </div>
          <div>
            <span
              v-for="(label, index) in otherCalendarLabels"
              :key="index"
              class="text-xs h-4"
            >
              <!-- <div
              class="h-3 w-3 inline-block rounded-full mr-2"
              :class="'bg-' + label.color"
            ></div> -->

              <span :class="'text-' + label.color"
                >{{ label.text
                }}<span v-if="index !== calendarLabels.length - 1" class="mr-1"
                  >.</span
                ></span
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="demo-alignment cust-popup-bg">
      <NotePopup
        :isActive="isNotePopupOpen"
        :title="notePopupTitle"
        @closePopup="toggleNotePopup"
      />
    </div>

    <div class="demo-alignment cust-popup-bg">
      <FXCalendarDay
        :isActive="isDayPopupOpen"
        @closePopup="toggleDayPopup"
        :params="dayParams"
        :fullscreen="isMobileView || !isNoteView"
        :noteView="isNoteView"
        :isLoggedIn="isLoggedIn"
      />
    </div>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");

import Datepicker from "vuejs-datepicker";
import { en, he } from "vuejs-datepicker/src/locale";

import NotePopup from "../components/NotePopup";
import CalenderMonthMobileView from "./CalenderMonthMobileView";
import FXCalendarDay from "./FXCalendarDay";
import CalendarHeader from "../components/CalendarHeader";
import SelectMonth from "../components/SelectMonth";

import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";
import jwt from "jsonwebtoken";
import { mapState } from "vuex";

import treasuryAnalyticsMixin from "@/Client/Kotak/mixins/treasuryAnalyticsMixin";

export default {
  mixins: [calendarMixin, treasuryAnalyticsMixin],
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    NotePopup,
    CalenderMonthMobileView,
    FXCalendarDay,
    CalendarHeader,
    SelectMonth,
  },
  data() {
    return {
      calendarPages: [
        {
          label: "FX",
          val: "fx-calendar",
        },

        {
          label: "MM",
          val: "money-market-calendar",
        },
      ],
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
      labelLocal: "none",

      langHe: he,
      langEn: en,

      url: "",
      calendarView: "month",

      calendarViewTypes: [
        // {
        //   label: "Month",
        //   val: "month"
        // },
        {
          label: "Week",
          val: "week",
        },
        // {
        //   label: "Year",
        //   val: "year"
        // }
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      isPopupOpen: false,
      popupData: {},
      isNotePopupOpen: false,
      notePopupTitle: "",
      isDayPopupOpen: false,
      dayParams: {},
      isLoggedIn: false,
      token: {},
      isNoteView: false,
    };
  },
  watch: {
    calendarView(val) {
      if (this.isMobileView && val === "week") {
        let header = `.cv-header-days`;
        let weekdays = `.cv-weekdays`;
        let dayNumber = `.cv-day-number`;
        const headerSelector = document.querySelector(header);
        const weekdaysSelector = document.querySelector(weekdays);
        const dayNumberSelector = document.querySelectorAll(dayNumber);

        headerSelector.className += ` week-view`;
        weekdaysSelector.className += ` weekday-view`;
        dayNumberSelector.forEach((element) => {
          element.className += ` week-day-number-view`;
        });
      } else if (this.isMobileView && val !== "week") {
        let header = `.cv-header-days`;
        let weekdays = `.cv-weekdays`;
        let dayNumber = `.cv-day-number`;
        const headerSelector = document.querySelector(header);
        const weekdaysSelector = document.querySelector(weekdays);
        const dayNumberSelector = document.querySelectorAll(dayNumber);
        headerSelector.className = headerSelector.className.replace(
          "week-view",
          ""
        );
        weekdaysSelector.className = weekdaysSelector.className.replace(
          "weekday-view",
          ""
        );
        dayNumberSelector.forEach((element) => {
          element.className = element.className.replace(
            "week-day-number-view",
            ""
          );
        });
      }
    },
    simpleCalendarEvents(newValue, oldValue) {
      if (oldValue.length !== newValue.length) {
        this.isDayPopupOpen = true;
        setTimeout(() => {
          this.isDayPopupOpen = false;
        }, 1);
      }
    },
  },
  computed: {
    simpleCalendarEvents() {
      return this.$store.state.calendar.fxEvents;
    },
    validForm() {
      return (
        this.title !== "" &&
        this.startDate !== "" &&
        this.endDate !== "" &&
        Date.parse(this.endDate) - Date.parse(this.startDate) >= 0 &&
        !this.errors.has("event-url")
      );
    },
    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },
    calendarLabels() {
      return this.$store.state.calendar.fxEventLabels;
    },
    otherCalendarLabels() {
      return this.$store.state.calendar.otherFXEventLabels;
    },
    lastWorkingDaysView() {
      const index = this.$store.state.calendar.lastWorkingDays.findIndex(
        (x) =>
          x.month ===
          `${
            this.months[this.showDate.getMonth()]
          } ${this.showDate.getFullYear()}`
      );
      const lastWorkingDays = this.$store.state.calendar.lastWorkingDays;

      return lastWorkingDays.slice(index === -1 ? 0 : index + 1, index + 13);
    },
    labelColor() {
      return (label) => {
        if (label === "Low") return "success";
        else if (label === "Medium") return "warning";
        else if (label === "High") return "danger";
        else if (label === "none") return "primary";
      };
    },
    excelData() {
      return this.$store.state.calendar.events.map((x) => ({
        date: x.startDate,
        ...x.otherDetails,
      }));
    },
    dayNote() {
      return (date) => {
        return this.getNote(date) && this.getNote.length
          ? this.getNote(date)
          : false;
      };
    },
    ...mapState("note", {
      notes: "notes",
    }),
    isItem() {
      return (elem) => {
        const item = document.getElementById(`${new Date(elem).toISOString()}`);
        return item;
      };
    },
    isOverflow() {
      return (elem) => {
        const item = document.getElementById(`${new Date(elem).toISOString()}`);
        return item ? item.scrollHeight > this.eventHeight : false;
      };
    },
    eventHeight() {
      return window.innerHeight / 10 + 1;
    },
    isWeekView() {
      return this.calendarView === "week";
    },
    viewButtonText() {
      return this.isWeekView ? "Month" : "Week";
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    navigateTo(route) {
      if (!route) return;
      this.$router.push({ name: route });
    },
    async getData(periodStart, periodEnd) {
      // For Month View
      // const date = this.showDate;
      // const year = date.getFullYear();
      // const month = date.getMonth();
      // const startDate = new Date(year, month, 1).toISOString().replace("T", "%20").replace("Z", "")
      // const endDate = new Date(year, month + 1, 0).toISOString().replace("T", "%20").replace("Z", "")

      //For Week View
      if (!periodStart && !periodEnd) return;
      const startDate = new Date(periodStart);
      const endDate = new Date(periodEnd);

      const payload = {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };

      const isLoggedIn = await this.checkLogin();

      const notePayload = {
        ...payload,
        userID: isLoggedIn ? this.token.uuid : "",
      };

      try {
        this.openLoadingInDiv();

        const data = await this.$store.dispatch("calendar/getFX", payload);

        this.setEvents(data);

        if (isLoggedIn) {
          await this.$store.dispatch("note/getNote", notePayload);
        }

        this.closeLoadingInDiv();
      } catch (error) {
        if (error) this.closeLoadingInDiv();
      }
    },
    formatDate(date) {
      const tzoffset = new Date().getTimezoneOffset() * 60000;
      return new Date(date.getTime() - tzoffset).toISOString();
    },
    periodChanged(values) {
      this.getData(values.periodStart, values.periodEnd);
    },
    togglePopup(val = false) {
      this.isPopupOpen = val;
    },
    openLoadingInDiv() {
      const element = document.getElementById("div-with-loading");
      if (element)
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
          type: "sound",
        });
    },
    closeLoadingInDiv() {
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },
    setEvents(payload) {
      const result = [];
      payload.forEach((element, index) => {
        const eventIndex = this.simpleCalendarEvents.findIndex(
          (x) => x._id === element._id
        );
        console.log(payload);
        if (eventIndex === -1) {
          //If event does not exist in the calendar
          let colorClass = `event-calendar-blue`;
          if (element.isHoliday) {
            console.log(element);
            // If event is a holiday
            // Get index of existing holiday on the same date
            const holidayIndex = result.findIndex(
              (x) =>
                x.title.includes(element.title) &&
                x.startDate === element.startDate
            );
            console.log(holidayIndex);
            //If holiday exists on same day prefix label to exisiting title
            if (holidayIndex !== -1) {
              result[holidayIndex].title = result[holidayIndex].title.replace(
                '<span class="text-primary uppercase">',
                ""
              );
              result[
                holidayIndex
              ].title = `<span class="text-primary  cursor-pointer uppercase"><span class=" font-semibold">${element.label}</span> / ${result[holidayIndex].title}`;
            } else {
              //If holiday does not exist on same day prefix label to title and add google search link of holiday
              element.title = `<span class="text-primary uppercase cursor-pointer"><span class="font-semibold">${element.label}</span> - <a class="holiday-alert " holiday='${element.title}'>${element.title}</a></span>`;
              result.unshift({
                ...element,
                classes: `${colorClass} mr-1 text-xs fx-event`,
              });
            }
          } else {
            //Find index of events with same label and date
            const labelIndex = result.findIndex(
              (x) =>
                x.label === element.label &&
                x.startDate === element.startDate &&
                !element.isHoliday
            );
            //If label is found suffix title to existing title
            if (labelIndex !== -1) {
              result[labelIndex].title += `,   <span class="text-calendar-blue font-semibold">${element.title} </span> `;
            } else {
              console.log(element)
              //If label is not found prefix label in title
              element.title = `<span class="text-calendar-blue font-semibold">${element.label} - ${element.title} </span> `;
              result.push({
                ...element,
                classes: `${colorClass} mr-1 text-xs fx-event event-calendar-blue`,
            
              });
            }
          }
          if (index === payload.length - 1) {
            //On last iteration send modified event array for grouping
            this.setFilteredEvents(result);
          }
        }
      });
    },
    async handleHolidayClick({ originalItem, title }) {
      const { isHoliday } = originalItem;
      if (!isHoliday) return;
      const query = this.createElementFromHTML(title);
      const result = await this.holidayAlert();
      if (result.value) {
        const url = `https://www.google.com/search?q=${query}`;
        window.open(url, "_blank");
      }
    },
    holidayAlert() {
      return this.$swal({
        html: "<p class='text-sm text-center line-h22'>Redirecting to a third party website for further information.</p>",

        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Proceed ",
        confirmButtonColor: "#ec1f30",
        cancelButtonColor: "#ccc",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        customClass: {
          cancelButton: "xm-size-b",
        },
      });
    },
    createElementFromHTML(htmlString) {
      const div = document.createElement("div");
      div.innerHTML = htmlString.trim();
      const holiday = div.getElementsByClassName("holiday-alert");
      // console.log(holiday[0].innerText, div);
      return holiday[0].innerText;
    },
    setFilteredEvents(result) {
      //Group events of same date into holiday and other
      const filtered = [];
      result.forEach((element, index) => {
        //Find index of elements based on date and holiday status
        const dateIndex = filtered.findIndex(
          (x) =>
            x.startDate === element.startDate &&
            x.isHoliday === element.isHoliday
        );
        //If index is found suffix title and add a break
        if (dateIndex !== -1) {
          filtered[dateIndex].title += `${element.title} <br>`;
        } else {
          //If index is not found add break to title
          element.title = `${element.title}<br>`;
          filtered.push(element);
        }
        if (index === result.length - 1) {
          //On last iteration send modified array for further grouping
          this.filterFilteredEvents(filtered);
        }
      });
    },
    filterFilteredEvents(result) {
      //Group events based on date
      const filter = [];

      result.forEach((element, index) => {
        //Find if event already exists in calendar
        const eventIndex = this.simpleCalendarEvents.findIndex(
          (x) => x.startDate === element.startDate
        );

        if (eventIndex === -1) {
          //If event does not already exist in calendar
          const dateIndex = filter.findIndex(
            (x) => x.startDate === element.startDate
          );
          if (dateIndex !== -1) {
            //If event of same date is found
            filter[dateIndex].title += `${element.title} <br>`;
          } else {
            //If event of same date is not found
            element.title = `${element.title}<br>`;
            filter.push(element);
          }
        }
        if (index === result.length - 1) {
          //Add Events to calendar for display
          filter.forEach((element) => {
            if (element.isBankHoliday) {
              let className = `.d${element.startDate.slice(0, 10)}`;
              const style = document.createElement("style");
              style.innerHTML = `${className} .cv-day-number { color: rgba(var(--vs-primary), 1) !important }`;
              document.head.appendChild(style);
            }
          });
          this.$store.commit("calendar/SET_FX_EVENTS", filter);
        }
      });
    },
    removeHTML(html) {
      if (!html) return;
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html.replace(/<br>/g, "break");
      return tmp.textContent || tmp.innerText || "";
    },
    itemClasses(classes) {
      if (!classes.length) return "";
      const css = classes;
      css.shift();
      return css.join(" ");
    },
    setTop(top) {
      top.replace("0*1.4em", "1*1.4em");
      return top;
    },
    handleDateClick(date, events) {
      // if (!this.isMobileView) return;
      const selectedDate = new Date(date);
      const params = {
        date: date,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        events: events,
        note: this.getNote(date),
      };
      this.dayParams = params;
      this.isNoteView = false;
      this.toggleDayPopup(true);
      // this.$router.push({
      //   name: "fx-calendar-day",
      //   params: {
      //     date: date,
      //     year: selectedDate.getFullYear(),
      //     month: selectedDate.getMonth(),
      //     day: selectedDate.getDate(),
      //     events: events,
      //     note: this.getNote(date)
      //   }
      // });
    },
    async toggleNotePopup(val = false, day = null) {
      const isLoggedIn = await this.checkLogin();
      if (isLoggedIn) {
        this.isNotePopupOpen = val;
        this.notePopupTitle = day;
      } else {
        this.$router.push({
          name: "page-kotak-login",
          params: {
            toFXCalendar: true,
          },
        });
      }
    },
    toggleDayPopup(val = false) {
      this.isDayPopupOpen = val;
      if (!val) {
        this.dayParams = {};
        this.isNoteView = false;
      }
    },
    getNote(date) {
      const now = this.getCurrentDate(date);

      const index = this.notes.findIndex((x) => x.date === now);

      return this.notes[index];
    },
    setWeekView(date) {
      const currentYear = new Date().getFullYear();
      if (date.getFullYear() !== currentYear) return;
      this.showDate = date;
      this.calendarView = "week";
    },
    scrollToEnd(elem) {
      const item = document.getElementById(`${new Date(elem).toISOString()}`);
      if (item && item.scrollTop > 0) {
        item.scrollTop = 0;
      } else if (item && item.scrollTop === 0) {
        item.scrollTop = item.scrollHeight;
      }
    },
    handleNoteClick(date) {
      const selectedDate = new Date(date);
      const params = {
        date: date,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        note: this.getNote(date),
      };
      this.dayParams = params;
      this.isNoteView = true;
      this.toggleDayPopup(true);
    },
    setMonthFromSelect(date) {
      this.showDate = date;
      if (!this.isWeekView) {
        // Switch to month view
        this.calendarView = "month";
      }
    },
    handleView() {
      if (this.isWeekView) {
        this.calendarView = "month";
      } else {
        this.calendarView = "week";
      }
    },
  },
  async created() {
    this.isLoggedIn = await this.checkLogin();
    if (this.isLoggedIn) {
      //Check if user is logged in
      let newtoken = localStorage.getItem("token");
      this.token = jwt.decode(newtoken);
      this.addViewedPromotionLog("fx-calendar"); // Add Visit log
      window.onbeforeunload = window.addEventListener(
        "beforeunload",
        this.updateViewedPromotionLog
      ); // Update Time spent
    }
    if (this.isMobileView) {
      // Set week view as default for mobile view
      this.calendarView = "week";
    }
  },
  mounted() {
    const weekNumber = this.getMonday(new Date());
    let className = `.ws${weekNumber} .cv-weeknumber`;
    const style = document.createElement("style");
    style.innerHTML = `${className} { background-color: rgba(var(--vs-warning), 1) !important }`;
    document.head.appendChild(style);
    this.trackPage("calendar/fx");
  },
};
</script>

<style lang="scss">
@import "@/Core/assets/scss/vuexy/apps/simple-calendar.scss";

#simple-calendar-app .theme-default .cv-weeks .cv-week .cv-item.fx-event {
  border: none;
  border: none;
  // font-weight: 500;
  border-radius: 0.25em;
  font-family: sans-serif !important;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-pack: end;
  // -ms-flex-pack: end;
  // justify-content: flex-end;
  // font-weight: bold;
  // word-break: break-all;
  white-space: normal;
  line-height: 14px;
  margin-top: 4px;
  cursor: unset !important;
}

@media (max-width: 575px) {
  #simple-calendar-app .theme-default .cv-header-days {
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
  }
  .mob-mt-10 {
    margin-top: 10px;
  }

  .sm-mt-4 {
    margin-top: 20px;
  }
  // .mob-h145 {
  //   height: 115px;
  // }
}
.cust-dotweek {
  height: 10px;
  width: 10px;

  border-radius: 50%;
  display: inline-block;
}
.height-scroll {
  height: 10vh;
  overflow-y: auto !important;
}
.height-scroll::-webkit-scrollbar {
  width: 0;
}
.rotate-scroll-icon {
  transform: rotate(135deg);
  width: 0.9rem !important;
}
.fsize13 {
  font-size: 13px;
}

.week-number-text {
  transform: rotate(90deg);
  width: 4rem;
}
.cust-w93 {
  width: 93px;

  @media (min-width: 671px) {
    width: 85px !important;
  }
}
</style>
<style>
.swal2-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.line-h22 {
  line-height: 22px;
}
.swal2-popup {
  width: 30em !important;
}
</style>
