<template>
  <vs-popup
    class="cust-vspopup-w500 "
    :title="formattedTitle"
    :active.sync="isActiveLocal"
  >
    <AddNote :date="title" @noteAdded="closePopup" />
  </vs-popup>
</template>

<script>
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

import AddNote from "./AddNote";

export default {
  mixins: [calendarMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    title: {
      default: "Note"
    }
  },
  components: {
    AddNote
  },
  watch: {
    isActive(val) {
      if (!val) {
        this.note = "";
      }
    }
  },
  computed: {
    isActiveLocal: {
      get() {
        return this.isActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup");
        }
      }
    },
    formattedTitle() {
      return this.title
        ? `${
            this.days[
              this.dayOfDate(
                this.title.getFullYear(),
                this.title.getMonth(),
                this.title.getDate()
              )
            ]
          }, ${
            this.months[this.title.getMonth()]
          } ${this.title.getDate()} ${this.title.getFullYear()}`
        : "";
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    }
  }
};
</script>
