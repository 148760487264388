<template>
  <div
    class="flex flex-wrap justify-between lg:px-1 md:px-1 bg-white pt-2 pb-2 mob-col-reverse mob-relative no-gutter"
  >
    <div
      class="vx-col min-pl-1 lg:w-1/3 min-justify-start mob-justify-end flex mob-header-tabs items-center"
    >
      <slot name="views"></slot>
    </div>

    <!-- Current Month -->
    <div
      class="vx-col mob-mt5-mb5 items-center lg:w-1/3 mob-eco-mb-4 flex justify-center"
    >
      <slot name="month"></slot>
    </div>
    <div
      class="vx-col lg:w-1/3 flex items-center lg:justify-end md:justify-end"
    >
      <!-- <div class="flex justify-end items-center mr-3 mob-cap-tabs-fx">
        <template v-for="(view, index) in calendarPages">
          <vs-button
            v-if="currentRoute === view.val"
            :key="String(view.val) + 'filled'"
            type="filled"
            class="min-px-4 fsize13 w-1/2 mob-h40 mob-c-pd border-radius-button"
            :class="{
              'border-l-0 rounded-l-none': index,
              'rounded-r-none': calendarPages.length !== index + 1,
            }"
            @click="navigateTo(view.val)"
            >{{ view.label }}</vs-button
          >
          <vs-button
            v-else
            :key="String(view.val) + 'border'"
            type="border"
            class="min-px-4 w-1/2 mob-h40 mob-c-pd fsize13 border-radius-button"
            :class="{
              'border-l-0 rounded-l-none': index,
              'rounded-r-none': calendarPages.length !== index + 1,
            }"
            @click="navigateTo(view.val)"
            >{{ view.label }}</vs-button
          >
        </template>
      </div> -->

      <div class="mob-flex mob-justify-center mob-w-full cursor-pointer">
        <img
          src="@/Core/assets/images/logo/logo-wh.jpg"
          class="logo mob-logo-align"
          alt="logo"
          @click="homepage"
        />
      </div>
      <vs-button
        v-if="isLoggedIn"
        color="danger"
        type="filled"
        icon="logout"
        radius
        class="md:mr-2 lg:mr-2 cust-h38 mob-position-log"
        @click="logout"
      ></vs-button>
    </div>
  </div>
</template>

<script>
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

export default {
  mixins: [calendarMixin],
  data() {
    return {
      calendarPages: [
        {
          label: "FX",
          val: "fx-calendar",
        },
        {
          label: "Eco",
          val: "economic-calendar",
        },
        {
          label: "MM",
          val: "money-market-calendar",
        },
      ],
      isLoggedIn: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    isMoneyMarket() {
      return this.currentRoute === "money-market-calendar";
    },
  },
  methods: {
    navigateTo(route) {
      if (!route) return;
      this.$router.push({ name: route });
    },
    homepage(){
      window.location.replace(window.location.origin);
    },
    logout() {
      localStorage.clear();
      window.location.replace(window.location.origin);
    },
  },
  async created() {
    this.isLoggedIn = await this.checkLogin();
  },
};
</script>
