/* eslint-disable no-console */
export default {
  methods: {
    trackPage(page) {
      // window.dataLayer = {};

      // dataLayer.page = {};

      window.digitalData = {};

      digitalData.page = [];

      digitalData.page.pageinfo = [];

      var pagename = page;

      digitalData.page.pageinfo.pageName = pagename;

      digitalData.page.category = [];

      var category = "";

      digitalData.page.category.primaryCategory = category;

      digitalData.website = [];

      var language;

      language = "English";

      digitalData.website.language = language;

      digitalData.web = [];

      digitalData.web.identifier = "treasury-calendar";

      // dataLayer.page.title = pagename;
      if (
        window._satellite &&
        _satellite.getVisitorId() &&
        _satellite.getVisitorId().getMarketingCloudVisitorID()
      ) {
        // window.dataLayer.visitorId = _satellite
        //   .getVisitorId()
        //   .getMarketingCloudVisitorID();
        window.digitalData.visitorId = _satellite
          .getVisitorId()
          .getMarketingCloudVisitorID();
      }
      // _satellite.track("calendar-dc");
      window._satellite.track("calendar-dc");
    },
  },
};
