<template>
  <vs-popup
    class="cust-vspopup-w500 cust-h-mob-pop"
    :title="title"
    :active.sync="isActiveLocal"
    :fullscreen="fullscreen"
  >
    <div
      v-if="isActiveLocal"
      class="mx-2 mt-1"
      :class="{ 'flex flex-col': !noteView }"
    >
      <div class="w-full" v-if="!noteView">
        <div class="overflow-y-auto">
          <p class="h2-class mr-2 text-start mb-4">Money Market</p>
          <div
            id="div-with-loading"
            class="vs-con-loading__container w-full h-8"
            v-if="isLoading && !isError"
          ></div>
          <div class="w-full h-8 flex justify-center" v-if="isError">
            <div class="text-center">
              <feather-icon
                icon="RotateCcwIcon"
                svgClasses="w-5 h-5 m-2"
                class="cursor-pointer hover:text-primary rounded-full w-8 h-8"
                @click="handleReload"
              ></feather-icon>

              <p>Reload</p>
            </div>
          </div>
          <MoneyMarketDayData
            :showDate="showDate"
            v-if="!isLoading && !isError"
          />
        </div>
      </div>
      <div :class="{ 'md:flex lg:flex': !noteView }">
        <div
          class="md:w-1/2 lg:w-1/2 md:mr-4 lg:mr-4 mob-mb2rem"
          v-if="!noteView"
        >
          <p class="h2-class mr-2 text-start mb-4 ">FX</p>

          <div
            v-if="value.title && !noteView"
            draggable="false"
            :class="`${value.classes.join(' ')} text-sm p-3  cust-w-bord`"
            :title="stripHTML(value.title)"
            v-html="value.title"
          ></div>
        </div>
        <DayNote
          :class="{ 'md:w-1/2 lg:w-1/2  md:ml-4 lg:ml-4': !noteView }"
          :note="note"
          :date="showDate"
          @noteEdited="handleNoteEdit"
          @noteDeleted="handleNoteDelete"
          v-if="isLoggedIn"
        />
      </div>
    </div>
  </vs-popup>
</template>

<script>
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";
import moneyMarketMixin from "@/Client/Kotak/mixins/moneyMarketMixin";

import { mapState } from "vuex";

import DayNote from "../components/DayNote";
import MoneyMarketDayData from "../components/MoneyMarketDayData";

export default {
  mixins: [calendarMixin, moneyMarketMixin],
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      required: true
    },
    noteView: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DayNote,
    MoneyMarketDayData
  },
  watch: {
    isActive(val) {
      if (!val) {
        this.resetParams();
      }
    },
    params() {
      this.setParams();
    }
  },
  computed: {
    isActiveLocal: {
      get() {
        return this.isActive;
      },
      set(val) {
        if (!val) {
          this.close();
        }
      }
    },
    title() {
      return `${
        this.days[
          this.dayOfDate(
            this.showDate.getFullYear(),
            this.showDate.getMonth(),
            this.day
          )
        ]
      }, ${this.months[this.month]} ${this.day} ${this.year}`;
    },
    ...mapState("calendar", {
      events: "moneyMarketEvents"
    })
  },
  data() {
    return {
      showDate: new Date(),
      value: {},
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      day: new Date().getDate(),
      note: {},
      isLoading: true,
      isError: false
    };
  },
  methods: {
    handleBack() {
      this.$router.push({ name: "fx-calendar" });
    },
    setParams() {
      const param = this.params;

      if (param.date) {
        this.showDate = param.date;
        this.getCalendarData(
          this.getCurrentDate(this.showDate),
          this.getCurrentDate(this.showDate)
        );
      }
      if (param.events) {
        this.value = param.events[0];
      }
      if (param.year) {
        this.year = param.year;
      }
      if (param.month) {
        this.month = param.month;
      }
      if (param.day) {
        this.day = param.day;
      }
      if (param.note) {
        this.note = param.note;
      }
    },
    resetParams() {
      this.showDate = new Date();
      this.value = {};
      this.month = new Date().getMonth();
      this.year = new Date().getFullYear();
      this.day = new Date().getDate();
      this.note = {};
    },
    close() {
      this.$emit("closePopup");
    },
    handleReload() {
      this.isError = false;
      this.getCalendarData(
        this.getCurrentDate(this.showDate),
        this.getCurrentDate(this.showDate)
      );
    },
    handleNoteDelete() {
      if (!this.noteView) return;
      this.close();
    }
  },
  created() {
    this.setParams();
  }
};
</script>

<style lang="scss">
@import "@/Core/assets/scss/vuexy/apps/simple-calendar.scss";

.cust-W95 {
  width: 95%;
}
</style>
