<template>
  <!-- <vs-select
                autocomplete
                   v-model="selectedMonth"
    class="cust-w100  invisiblescroll-select"
    :class="{
     
      'cust-select-month ': !moneyMarket,
      'cust-select-month-mm': moneyMarket
    }"
              >
                <vs-select-item
          :key="index"
      :value="item.value"
      :text="item.text"
      v-for="(item, index) in months"
      class="cust-w93 "
                />
              </vs-select> -->
  <div>
    <b-form-select
      class=" bg-white  mob-h40"
      :class="{
        ' select-otpion': view === 'month',
        'cust-select-month': !moneyMarket,
        'cust-select-month-mm': moneyMarket
      }"
      v-model="selectedMonth"
      :options="months"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BFormSelect } from "bootstrap-vue";
export default {
  props: {
    showDate: {
      type: Date,
      default: new Date()
    },
    view: {
      type: String,
      default: "month"
    },
    moneyMarket: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BFormSelect,
    "v-select": vSelect
  },
  watch: {
    selectedMonth(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setMonth(newValue, oldValue);
      }
    },
    showDate(val) {
      this.selectedMonth = val.getMonth();
    }
  },
  data() {
    return {
      months: [
        {
          value: 0,
          text: "January"
        },
        {
          value: 1,
          text: "February"
        },
        {
          value: 2,
          text: "March"
        },
        {
          value: 3,
          text: "April"
        },
        {
          value: 4,
          text: "May"
        },
        {
          value: 5,
          text: "June"
        },
        {
          value: 6,
          text: "July"
        },
        {
          value: 7,
          text: "August"
        },
        {
          value: 8,
          text: "September"
        },
        {
          value: 9,
          text: "October"
        },
        {
          value: 10,
          text: "November"
        },
        {
          value: 11,
          text: "December"
        }
      ],
      selectedMonth: new Date().getMonth()
    };
  },
  methods: {
    setMonth(newValue, oldValue) {
      let date = new Date();
      if (newValue !== oldValue) {
        const newMonth = new Date().setMonth(this.selectedMonth);

        //Fix if current last day of month does not exist in next month, next month gets skipped issue
        if (new Date(newMonth).getMonth() > this.selectedMonth) {
          const dateToChange = newValue > oldValue ? 3 : 18;
          const monthToChange = new Date().setMonth(
            this.selectedMonth,
            dateToChange
          );
          date = new Date(monthToChange);
        } else {
          date = new Date(newMonth);
        }

        if (
          this.moneyMarket &&
          newValue !== oldValue &&
          this.showDate.getDate() < 15
        ) {
          date.setDate(3);
          this.emitShowDate(date);
        } else {
          this.emitShowDate(date);
        }
      }
    },
    emitShowDate(date) {
      this.$emit("monthChanged", date);
    }
  }
};
</script>
